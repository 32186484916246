import { useAuthStore } from "~/auth/stores/auth"

export default function useAccessControl() {
  const auth = useAuthStore()
  const userPermissions: string[] | undefined = auth?.user?.permissions
  const userRoles: string[] | undefined = auth?.user?.roles
  const userCan = (permissions: string | string[] | undefined) => {
    if (!permissions) return

    const routePermissions: string[] =
      typeof permissions === "string" ? [permissions] : permissions

    return routePermissions.some((permission: string) => {
      return userPermissions?.includes(permission)
    })
  }

  const hasRoles = (roles: string | string[] | undefined) => {
    if (!roles) return
    const myRoles: string[] = typeof roles === "string" ? [roles] : roles
    return myRoles.some((role: string) => userRoles?.includes(role))
  }

  return {
    userCan,
    hasRoles,
  }
}
